import styled from '@emotion/styled';

export const SContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 100%;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

export const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

export const SModalParagraph = styled.p`
  margin-top: 30px;
`;

export const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  width: 100%;

`;