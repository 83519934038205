import {createTheme, responsiveFontSizes} from "@mui/material";

let themeOptions = createTheme({
    palette: {
        primary: {
            main: '#5e35b1',
        },
        secondary: {
            main: '#5e35b1',
            contrastText: '#ede7f6',
        },
        text: {
            primary: '#303030',
        },
        background: {
            default: '#eeeeee',
            paper: '#fafafa',
        },
        divider: '#d1c4e9',
    },
    components: {
        // Name of the component
        MuiCard: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    border: '1px solid #d1c4e9',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '1rem',
                    width: '350px',
                    height: '500px',
                    margin: '10px'
                },
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    flexGrow: 1
                }
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '20px'
                }
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    width: '90%'
                }
            },
        },
        MuiCardActions: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection:"column",
                    borderTop: '1px solid #d1c4e9',
                    padding: '20px',
                    alignSelf: 'flex-end',
                    marginBottom: '0px auto',
                    width: '100%',
                    bottom: '0px'
                }
            },
        },
    },
});

themeOptions.spacing(10);
themeOptions = responsiveFontSizes(themeOptions);

export {themeOptions};