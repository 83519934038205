import * as React from 'react';
import {Alert, CircularProgress} from '@mui/material'
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import {getNativeCurrency} from "../helpers/utilities";
import {TOKEN_NAME} from "../constants";

export default function DialogAmount(props: any) {
    const {chainId, title, text, open, result, button, error_max_0, balance, tokenBalance, nativeBalance, type, fetching} = props;

    const tb = tokenBalance ? tokenBalance.value : 0n;
    const nb = nativeBalance ? nativeBalance.value : 0n;
    const b = balance ? balance.value : 0n;

    const [value, setValue] = React.useState(
        100n
    );

    const handleInputChange = (event: any, newValue: any) => {
        setValue(BigInt(newValue));
    };

    return (open && b == 0n) ? (
            <Alert severity="error" action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => { result(undefined) ; }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
                   sx={{ mb: 2 }}
            >
                {error_max_0}
            </Alert>
        ) :
        (
        <Dialog open={open} onClose={() => { result() ; }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    marginBottom: '20px'
                }}>
                    {text}
                </DialogContentText>
                <Slider defaultValue={parseInt(value.toString())} onChange={handleInputChange} aria-label="Default" valueLabelDisplay="auto" />
                <DialogContentText sx={{
                    marginTop: '20px'
                }}>
                    Amount: {
                        (parseFloat((tb*value/100n).toString())/1e8).toFixed(2)
                    } {TOKEN_NAME} / {
                        (parseFloat((nb*value/100n).toString())/1e18).toFixed(2)
                    } {
                        getNativeCurrency(chainId).symbol
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { result() ; }}>Cancel</Button>
                {fetching ? <CircularProgress /> : <Button onClick={() => {
                    if (type == "add_liq")
                    {
                        result(tb*value/100n,
                            nb*value/100n);

                    }
                    else
                    {
                        result(b*value/100n);
                    }
                }}>{button}</Button>}
            </DialogActions>
        </Dialog>
    );
}
