import * as React from "react";
import * as localforage from "localforage";
import { useWeb3Modal } from '@web3modal/react'
import {useCallback, useEffect, useState} from "react";
import {
    TOKEN_NAME,
    TOKEN_FULL_NAME,
    ELECTRUM_CONFIG,
    BRIDGE_CONFIG,
    TOKEN_CONTRACT,
    GAS_SIZE,
    LP_CONTRACT, FARM_CONTRACT, ROUTER_CONTRACT, NATIVE_CONTRACT
} from "./constants";
import {FetchBalanceResult} from "./helpers/types";

import {useAccount, useChainId, useContractRead, useBalance, useBlockNumber, useContractWrite} from 'wagmi'
import { getPublicClient } from '@wagmi/core'
import {Alert, CircularProgress, Grid, ThemeProvider} from "@mui/material";
import {themeOptions} from "./theme/theme";
import Drawer from "./components/Drawer";
import {isValidChain} from "./helpers/web3";
import DialogAmount from "./components/DialogAmount";
import {SContent} from "./components/Base";
import Button from "@mui/material/Button";
import {getNativeCurrency} from "./helpers/utilities";
import ConfirmationDialog from "./components/ConfirmationDialog";
import CardContainer from "./components/CardContainer";
import AccountAssets from "./components/AccountAssets";
import {LiquidityPoolCard} from "./components/LiquidityCard";
import Farming from "./components/Farming";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";
import {DECIMALS} from "./constants/contracts";
import {write} from "node:fs";


window.Buffer = window.Buffer || require("buffer").Buffer;

const ElectrumClient = require("@aguycalled/electrum-client-js");
const Bitcore = require("@aguycalled/bitcore-lib")

const validateAddress = (address: string) => {
    return Bitcore.Address.isValid(address)
}

function Bridge () {
    const { open } = useWeb3Modal()
    const {  isConnected, address } = useAccount()
    const chainId = useChainId();
    const [validChain, setValidChain] = useState(false);
    const [nativeAddress, setNativeAddress] = useState(NATIVE_CONTRACT[chainId].address as  `0x${string}`);
    const [tokenAddress, setTokenAddress] = useState(TOKEN_CONTRACT[chainId].address as  `0x${string}`);
    const [lpAddress, setLpAddress] = useState(LP_CONTRACT[chainId].address as  `0x${string}`);
    const [farmAddress, setFarmAddress] = useState(FARM_CONTRACT[chainId].address as  `0x${string}`);
    const [routerAddress, setRouterAddress] = useState(ROUTER_CONTRACT[chainId].address as  `0x${string}`);

    useEffect(() => {
        if (chainId == -1) return;
        setFetching2(true)

        setValidChain(isValidChain(chainId))
        setNativeAddress(NATIVE_CONTRACT[chainId].address as `0x${string}` )
        setTokenAddress(TOKEN_CONTRACT[chainId].address as `0x${string}` )
        setLpAddress(LP_CONTRACT[chainId].address as `0x${string}` )
        setRouterAddress(ROUTER_CONTRACT[chainId].address as `0x${string}` )
        setFarmAddress(FARM_CONTRACT[chainId].address as `0x${string}` )

        let coldAddresses = [];
        let conf = BRIDGE_CONFIG

        for (var i in conf["coldStorage"]["keys"]) {
            let obj = conf["coldStorage"]["keys"][i];

            const childPath = "0x" + chainId + (String(chainId).length % 2 ? '0' : '') + TOKEN_CONTRACT[chainId].address.substr(2) + "c01d";
            let navObj = Bitcore.HDPublicKey(obj).deriveChild(childPath).publicKey

            coldAddresses.push(navObj);
        }

        let multisigScript = Bitcore.Script.buildMultisigOut(coldAddresses, conf["coldStorage"]["requiredSigs"])
        let coldStorageScript = new Bitcore.Script.fromAddresses(conf["stakingPoolAddress"], multisigScript)
        setColdStorageScriptHash(Buffer.from(Bitcore.crypto.Hash.sha256(coldStorageScript.toBuffer()).reverse()).toString("hex"))

        let stakingNavAddressObj = Bitcore.HDPublicKey(conf.publicKeyNav).deriveChild("0x" + String(chainId) + (String(chainId).length % 2 ? '0' : '') + TOKEN_CONTRACT[chainId].address.substr(2) + "5743").publicKey.toAddress('mainnet')
        let stakingNavAddressStr = stakingNavAddressObj.toString()
        console.log('bridge staking address', stakingNavAddressStr)
        setStakingScriptHash(Buffer.from(Bitcore.crypto.Hash.sha256(Bitcore.Script.buildPublicKeyHashOut(stakingNavAddressObj).toBuffer()).reverse()).toString("hex"))

        setFetching2(false)
    }, [chainId]);

    const [electrumClient, setElectrumClient] = useState(new ElectrumClient(ELECTRUM_CONFIG.host, ELECTRUM_CONFIG.port, ELECTRUM_CONFIG.proto));
    const [fetching, setFetching] = useState(false);
    const [fetching2, setFetching2] = useState(false);
    const [fetching3, setFetching3] = useState(false);
    const [fetching4, setFetching4] = useState(false);
    const [farmingFetching, setFarmingFetching] = useState(false);
    const [totalSupply, setTotalSupply] = useState(0n);
    const [lpTotalSupply, setLpTotalSupply] = useState(0n);
    const [lpTotalFarmed, setLpTotalFarmed] = useState(0n);
    const [lpUserFarmed, setLpUserFarmed] = useState(0n);
    const [nativeBalance, setNativeBalance] = useState<FetchBalanceResult>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [tokenBalance, setTokenBalance] = useState<FetchBalanceResult>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [dialogNativeBalance, setDialogNativeBalance] = useState<FetchBalanceResult>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [dialogTokenBalance, setDialogTokenBalance] = useState<FetchBalanceResult>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [lpBalance, setLpBalance] = useState<FetchBalanceResult>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [isRegistered, setIsRegistered] = useState(false);
    const [lpAllowance, setLpAllowance] = useState(0n);
    const [routerAllowance, setRouterAllowance] = useState(0n);
    const [routerAllowanceNative, setRouterAllowanceNative] = useState(0n);
    const [depositAddress, setDepositAddress] = useState("");
    const [depositScriptHash, setDepositScriptHash] = useState<string>("");
    const [coldStorageScriptHash, setColdStorageScriptHash] = useState<string>("");
    const [stakingScriptHash, setStakingScriptHash] = useState<string>("");
    const [withdrawalAverage, setWithdrawalAverage] = useState<number>(0);
    const [gasCost, setGasCost] = useState<number>(0);
    const [token0Lp, setToken0Lp] = useState<string>("");
    const [token1Lp, setToken1Lp] = useState<string>("");
    const [reservesLp, setReservesLp] = useState<bigint[]>([]);
    const [reserves, setReserves] = useState<{[key: string]: bigint}>({});
    const [depositedNAVLp, setDepositedNAVLp] = useState<bigint>(0n);
    const [depositedBNBLp, setDepositedBNBLp] = useState<bigint>(0n);
    const [share, setShare] = useState<bigint>(0n);
    const [pendingTotalRewards, setPendingTotalRewards] = useState<bigint>(0n);
    const [userRewards, setUserRewards] = useState<bigint>(0n);
    const [expectedNavPerYear, setExpectedNavPerYear] = useState<bigint>(0n);
    const [pendingToDistribute, setPendingToDistribute] = useState<bigint>(0n);
    const [totalPending, setTotalPending] = useState<bigint>(0n);
    const [stakesCount, setStakesCount] = useState<bigint>(0n);
    const [stakingBalance, setStakingBalance] = useState<bigint>(0n);
    const [apy, setApy] = useState<bigint>(0n);
    const [blockNumber, setBlockNumber] = useState<bigint>(0n);
    const [electrumFetching, setElectrumFetching] = useState<boolean>(false);
    const [electrumConnected, setElectrumConnected] = useState<boolean>(false);
    const [assets, setAssets] = useState<{ symbol: string; name: string; balance: FetchBalanceResult; decimals: number; contractAddress: `0x${string}`; }[]>([])
    const [dialogFetching, setDialogFetching] = useState(false);

    useBalance({address: address, onSuccess: setNativeBalance, watch: true})
    useBalance({address: address, token: tokenAddress, onSuccess: setTokenBalance, watch: true})
    useBalance({address: address, token: lpAddress, onSuccess: setLpBalance, watch: true})

    useEffect(() => {
        if (address) {
            setFetching(true);
            let navAddress = Bitcore.HDPublicKey(BRIDGE_CONFIG.publicKeyNav).deriveChild(address + chainId + (String(chainId).length % 2 ? '0' : '') + tokenAddress.substr(2)).publicKey.toAddress('mainnet')
            setDepositAddress(navAddress.toString())
            setDepositScriptHash(Buffer.from(Bitcore.crypto.Hash.sha256(Bitcore.Script.buildPublicKeyHashOut(navAddress).toBuffer()).reverse()).toString("hex"))
            setFetching(false);
        }
    }, [address, chainId, tokenAddress])

    useEffect(() => {
        const Function = async () => {
            if (blockNumber === 0n || stakingScriptHash === "" || electrumFetching || !electrumConnected)
                return;
            setElectrumFetching(true);

            const publicClient = getPublicClient()
            let newWithdrawalAverage = []; let sum = 0;
            let last10Withdrawals = await fetch('https://api.navcoin.org/address/NKuyBkoGdZZSLyPbJEetheRhMjeznFZszf/history?sort=height:desc,txindex:desc&size=10&page=1&filters=type:sending');
            if (last10Withdrawals.ok) {
                let j = await last10Withdrawals.json();
                for (let tx of j) {
                    let txDetail = await fetch('https://api.navcoin.org/tx/'+tx.txid);
                    if (txDetail.ok) {
                        try {
                            let parsed = JSON.parse((await txDetail.json()).strdzeel);
                            if (!parsed.burn || !parsed.burn.transactionHash) continue;
                            let tx_ = (await publicClient.getTransaction({hash: parsed.burn.transactionHash}))
                            if (!tx_) continue;
                            let bn = tx_.blockNumber
                            if (bn === undefined) continue;
                            let ethTx = await publicClient.getBlock({blockNumber: bn})
                            newWithdrawalAverage.push([tx.txid, parsed.burn.transactionHash, parseInt(ethTx.timestamp.toString())-Math.floor(new Date(tx.time).getTime()/1e3)]);
                            sum += Math.floor(new Date(tx.time).getTime()/1e3)-parseInt(ethTx.timestamp.toString());
                        } catch(e) { console.log(e); }
                    }
                }
                setWithdrawalAverage(sum / newWithdrawalAverage.length);
            }

            let gasPrice = parseInt((await publicClient.getGasPrice()).toString()) / 1000000000;
            var mintCost = (GAS_SIZE*gasPrice)/100000000;
            setGasCost(BRIDGE_CONFIG.feeMint * (0.0001 / mintCost));

            let navBlockNumber = (await electrumClient.blockchain_headers_subscribe()).height;

            let history = await electrumClient.blockchain_scripthash_getHistory(stakingScriptHash, parseInt(navBlockNumber.toString())-500)

            let stakesCount = 0;

            for (var i in history.history) {
                var entry = history.history[i]

                if (navBlockNumber - entry.height < 480) {
                    var tx = await electrumClient.blockchain_transaction_getMerkle(entry.tx_hash);
                    if (tx.pos == 1) stakesCount++;
                }
            }

            let stakingBalance = (await electrumClient.blockchain_scripthash_getBalance(stakingScriptHash)).confirmed;

            setStakesCount(BigInt(stakesCount));
            setStakingBalance(BigInt(stakingBalance));

            console.log('since block',parseInt(navBlockNumber.toString())-500,'there have been', stakesCount,'stakes')
            console.log('pending rewards', stakingBalance)

            setElectrumFetching(false);
        }

        Function();
    },[blockNumber, stakingScriptHash, electrumConnected])

    const [successTokenApprove, setSuccessTokenApprove] = useState<any>(() => {});
    const [successLpApprove, setSuccessLpApprove] = useState<any>(() => {});
    const [successNativeApprove, setSuccessNativeApprove] = useState<any>(() => {});

    useContractRead({
        abi: TOKEN_CONTRACT.abi,
        address: tokenAddress as `0x${string}`,
        functionName: 'totalSupply',
        onSuccess: setTotalSupply
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'totalSupply',
        onSuccess: setLpTotalSupply
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'balanceOf',
        args: [farmAddress],
        onSuccess: setLpTotalFarmed
    })

    useContractRead({
        abi: FARM_CONTRACT.abi,
        address: farmAddress as `0x${string}`,
        functionName: 'deposited',
        args: [0, address],
        onSuccess: setLpUserFarmed
    })

    useContractRead({
        abi: TOKEN_CONTRACT.abi,
        address: tokenAddress as `0x${string}`,
        functionName: 'isRegistered',
        args: [address],
        onSuccess: setIsRegistered
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'allowance',
        args: [address, farmAddress],
        onSuccess: setLpAllowance
    })

    useContractRead({
        abi: TOKEN_CONTRACT.abi,
        address: tokenAddress as `0x${string}`,
        functionName: 'allowance',
        args: [address, routerAddress],
        onSuccess: setRouterAllowance
    })

    useContractRead({
        abi: NATIVE_CONTRACT.abi,
        address: nativeAddress as `0x${string}`,
        functionName: 'allowance',
        args: [address, routerAddress],
        onSuccess: setRouterAllowanceNative
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'token0',
        onSuccess: setToken0Lp
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'token1',
        onSuccess: setToken1Lp
    })

    useContractRead({
        abi: LP_CONTRACT.abi,
        address: lpAddress as `0x${string}`,
        functionName: 'getReserves',
        onSuccess: setReservesLp
    })

    useContractRead({
        abi: FARM_CONTRACT.abi,
        address: farmAddress as `0x${string}`,
        functionName: 'totalPending',
        onSuccess: setTotalPending
    })

    useContractRead({
        abi: FARM_CONTRACT.abi,
        address: farmAddress as `0x${string}`,
        functionName: 'pending',
        args: [0, address],
        onSuccess: setUserRewards
    })

    useBlockNumber({onSuccess: setBlockNumber})

    const {write: writeBurn } = useContractWrite({
        address: tokenAddress,
        abi: TOKEN_CONTRACT.abi,
        functionName: 'burnWithNote'
    })

    const {write: writeAddLiquidity } = useContractWrite({
        address: routerAddress,
        abi: ROUTER_CONTRACT.abi,
        functionName: 'addLiquidityETH',
        onSuccess: () => {
            setDialogFetching(false);
        }
    })

    const {write: writeRemoveLiquidity } = useContractWrite({
        address: routerAddress,
        abi: ROUTER_CONTRACT.abi,
        functionName: 'removeLiquidityETH',
        onSuccess: () => {
            setDialogFetching(false);
        }
    })

    const {write: writeDepositFarm } = useContractWrite({
        address: farmAddress,
        abi: FARM_CONTRACT.abi,
        functionName: 'deposit',
        onSuccess: () => {
            setDialogFetching(false);
        },
        onError: () => {
            setDialogFetching(false);
        }
    })

    const {write: writeRegister } = useContractWrite({
        address: tokenAddress,
        abi: TOKEN_CONTRACT.abi,
        functionName: 'register',
        onSuccess: () => {
            setDialogFetching(false);
        },
        onError: () => {
            setDialogFetching(false);
        }
    })

    const {write: writeWithdraw } = useContractWrite({
        address: farmAddress,
        abi: FARM_CONTRACT.abi,
        functionName: 'withdraw',
        onSuccess: () => {
            setDialogFetching(false);
        },
        onError: () => {
            setDialogFetching(false);
        }
    })

    const {write: writeTokenApprove } = useContractWrite({
        address: tokenAddress,
        abi: TOKEN_CONTRACT.abi,
        functionName: 'approve',
        onSuccess: successTokenApprove
    })

    const {write: writeNativeApprove } = useContractWrite({
        address: nativeAddress,
        abi: NATIVE_CONTRACT.abi,
        functionName: 'approve',
        onSuccess: successNativeApprove
    })

    const {write: writeLpApprove } = useContractWrite({
        address: lpAddress,
        abi: LP_CONTRACT.abi,
        functionName: 'approve',
        onSuccess: successLpApprove
    })

    useEffect(() => {
        if (lpTotalSupply == 0n)
            return;

        setFetching3(true);
        let Token0 = token0Lp.toLowerCase() == tokenAddress.toLowerCase() ? TOKEN_NAME : 'BNB';
        let Token1 = token1Lp.toLowerCase() == tokenAddress.toLowerCase() ? TOKEN_NAME : 'BNB';

        let reserves_ : {[key: string]: bigint} = {}
        reserves_[Token0] = reservesLp[0];
        reserves_[Token1] = reservesLp[1];

        setReserves(reserves_);

        console.log('user farmed', lpUserFarmed)
        console.log('lp total supply', lpTotalSupply)

        let userLpShare = lpUserFarmed * 1000000000n / lpTotalSupply
        console.log('user share of lp', parseInt(userLpShare.toString())*100/1000000000)
        let navInLp = reserves_.WNAV * userLpShare / 1000000000n

        setDepositedNAVLp(navInLp)
        console.log('nav in lp', parseInt(navInLp.toString())/1e18)

        let bnbInLp = reserves_.BNB * userLpShare / 1000000000n;
        console.log('bnb in lp', parseInt(bnbInLp.toString())/1e18)

        setDepositedBNBLp(bnbInLp)
        let share = lpUserFarmed * 1000000n  / lpTotalFarmed

        console.log('share', share)
        setShare(share)

        let totalFarmedNav = reserves_.WNAV * lpTotalFarmed / lpTotalSupply;

        setPendingTotalRewards(totalPending * lpUserFarmed / lpTotalFarmed)

        console.log('lpfarmallowance', lpAllowance, 'lpBalance', lpBalance.value)

        setNeedApproval3(lpAllowance < lpBalance.value)

        console.log('user share', share)

        let expectedNavPerYear = stakesCount * 180000000n * 2100n;

        setExpectedNavPerYear(expectedNavPerYear)
        console.log('expected staking rewards in one year', expectedNavPerYear)

        setPendingToDistribute(stakingBalance * share / 1000000n)
        console.log('navinlp', navInLp)

        let apy_ = navInLp > 0 ? (expectedNavPerYear * 1000000n / (totalFarmedNav)) : 0n;

        setApy(apy_)
        console.log('apy', apy_)
        setFetching3(false);
    }, [tokenAddress, lpTotalSupply, lpUserFarmed, lpTotalFarmed, token0Lp, token1Lp, reservesLp, totalPending, userRewards, stakesCount, stakingBalance, lpAllowance, lpBalance])

    useEffect(() => {
        let symbols = [{symbol: TOKEN_NAME, name: TOKEN_FULL_NAME, balance: tokenBalance, decimals: 8, contractAddress: tokenAddress}]
        symbols.push(
            {symbol: 'BNB', name: 'Binance Coin', balance: nativeBalance, decimals: 18, contractAddress: '0xad'})
        symbols.push(
            {symbol: 'LP', name: 'Pancake LP', balance: lpBalance, decimals: 18, contractAddress: lpAddress})
        setAssets(symbols);
    }, [lpAddress, tokenAddress,nativeBalance, tokenBalance, lpBalance])

    useEffect(() => {
        (async () => {
            if (isConnected) {
                await electrumClient.connect('bridge', '1.5');
                setElectrumConnected(true);
                //await subscribeTokens();
                //await getAccountAssets();
                //await getBridgeSupply();
                //await getFarmingInfo();
                //await getStakingInfo();
                //await getRewardsInfo();
            }
        })()
    }, [isConnected])

    const [section, setMenuSelected] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogButton, setDialogButton] = useState("");
    const [dialogResult, setDialogResult] = useState<any>(undefined);
    const [dialogError_max_0, setDialogError_max_0] = useState("");
    const [dialogBalance, setDialogBalance] = useState<{
        decimals: number,
        formatted: string,
        symbol: string,
        value: bigint
    }|{
        value: bigint
    }>({
        decimals: 0,
        formatted: "",
        symbol: "",
        value: 0n
    });
    const [dialogType, setDialogType] = useState('');
    const [needApproval, setNeedApproval] = useState(false);
    const [needApproval2, setNeedApproval2] = useState(false);
    const [needApproval3, setNeedApproval3] = useState(false);

    const getAmountOut = useCallback((amount: bigint, in_: string, out: string) => {
        let rr = 0n;
        if (reserves) {
console.log('amount', amount);
console.log('reserves out', reserves[out], out);
console.log('reserves in', reserves[in_], in_);
            rr = amount * reserves[out] / reserves[in_] * 9975n / 10000n;
            /*const amountInWithFee = amount * 9975n;
            const numerator = amountInWithFee * reserves[out] / DECIMALS[out];
            const denominator = reserves[in_] * 10000n / DECIMALS[in_] + amountInWithFee ;
            rr = numerator / denominator;*/
        }
        return rr;
    }, [reserves]);

    const onAddLiquidity = useCallback(() => {
        //if (routerAllowanceNative && routerAllowance) {
            let bal_tok = tokenBalance;

            console.log('nativeBalance', nativeBalance.value);          
	    let bal_nat = {...nativeBalance, value: nativeBalance.value-BigInt(1e15)};

            bal_nat = {...bal_nat, value: getAmountOut(bal_tok.value, TOKEN_NAME, getNativeCurrency(chainId).symbol)}

            console.log(bal_nat.value, nativeBalance.value, bal_nat.value > nativeBalance.value, getAmountOut(bal_tok.value, TOKEN_NAME, getNativeCurrency(chainId).symbol))

            if (nativeBalance && bal_nat.value > nativeBalance.value)
            {
                bal_nat = {...bal_nat, value:nativeBalance.value};
                bal_tok = {...bal_tok, value:getAmountOut(nativeBalance.value, getNativeCurrency(chainId).symbol, TOKEN_NAME)}

            }

            setDialogNativeBalance(bal_nat)
            setDialogTokenBalance(bal_tok)

            setNeedApproval(routerAllowance < tokenBalance.value)
            setNeedApproval2(routerAllowanceNative < nativeBalance.value)

            setDialogOpen(true);
            setDialogTitle('Add tokens to Liquidity Pool')
            setDialogText('Indicate the amount of tokens you would like to add to the liquidity pool')
            setDialogBalance(bal_nat)
            setDialogButton(needApproval ? 'Approve '+ TOKEN_NAME : needApproval2 ? 'Approve BNB' : 'Add to LP')
            setDialogResult(() => async (tokamount: bigint, amount: bigint) => {
                if (amount > 0) {
                    if (needApproval) {
                        writeTokenApprove({args:[
                                routerAddress,
                                BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                            ]})
                        setSuccessTokenApprove(() => () => {
                            setNeedApproval(false);
                            setDialogButton(needApproval2 ? 'Approve BNB' : 'Add to LP');
                        })
                        return;
                    }

                    if (needApproval2) {
                        writeNativeApprove({args:[
                                routerAddress,
                                BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                            ]})
                        setSuccessNativeApprove(() => () => {
                            setNeedApproval2(false);
                            setDialogButton('Add to LP');
                        })
                        return;
                    }

                    const publicClient = getPublicClient()
                    const timestamp = (await publicClient.getBlock({blockNumber: await publicClient.getBlockNumber()})).timestamp;


                    console.log('writeAddLiquidity', 'send', amount * 90n / 100n, 'params', [tokamount,
                        tokamount * 90n / 100n,
                        amount * 90n / 100n])

                    writeAddLiquidity({
                        args:[
                            tokenAddress,
                            tokamount,
                            tokamount * 90n / 100n,
                            amount * 90n / 100n,
                            address,
                            timestamp+10000n
                        ],
                        value: amount
                    })
                }

                setDialogOpen(false);
            })
            setDialogError_max_0('You have no balance to add!')
            setDialogType('add_liq')
        //}
    }, [address, chainId, needApproval, needApproval2, routerAddress, tokenAddress, writeAddLiquidity, writeNativeApprove, writeTokenApprove, reserves, reservesLp, nativeBalance, tokenBalance, routerAllowance, routerAllowanceNative])

    return (
        <ThemeProvider theme={themeOptions}>
            <Drawer
                theme={themeOptions}
                connected={isConnected}
                address={address}
                chainId={chainId}
                killSession={() => open()}
                sectionSelected={setMenuSelected}
                addedAsset={true}
                scAddress={tokenAddress}>
                <DialogAmount open={dialogOpen}
                              text={dialogText}
                              title={dialogTitle}
                              button={dialogButton}
                              result={dialogResult}
                              error_max_0={dialogError_max_0}
                              balance={dialogBalance}
                              tokenBalance={dialogTokenBalance}
                              nativeBalance={dialogNativeBalance}
                              type={dialogType}
                              chainId={chainId}
                              fetching={dialogFetching}
                />
                <SContent>
                    {!validChain ? (
                        <Alert severity="error">The selected network is currently not supported.</Alert>
                    ) : (fetching || fetching2 || fetching3 || fetching4) ? (
                            <CircularProgress />
                    ) : isConnected ? section == 1 ? (
                            <>
                                <CardContainer>
                                    <AccountAssets chainId={chainId} address={address} tokenBalance={tokenBalance}
                                                   assets={assets}
                                                   onRemove={() => {
                                                       if (routerAllowance) {
                                                           setNeedApproval(routerAllowance < lpBalance.value);

                                                           setDialogOpen(true);
                                                           setDialogTitle('Remove tokens from Liquidity Pool')
                                                           setDialogText('Indicate the amount of tokens you would like to remove from the liquidity pool')
                                                           setDialogBalance(lpBalance)
                                                           setDialogNativeBalance(
                                                               {
                                                                   decimals: 18,
                                                                   formatted: "",
                                                                   symbol: getNativeCurrency(chainId).symbol,
                                                                   value: reserves[getNativeCurrency(chainId).symbol] * lpBalance.value / lpTotalSupply
                                                               })
                                                           setDialogTokenBalance(
                                                               {
                                                                   decimals: 8,
                                                                   formatted: "",
                                                                   symbol: TOKEN_NAME,
                                                                   value: reserves[TOKEN_NAME] * lpBalance.value / lpTotalSupply
                                                               })
                                                           setDialogButton(!needApproval ? 'Remove from LP' : 'Approve')
                                                           setDialogResult(() => async (amount: bigint) => {
                                                               if (amount > 0) {
                                                                   if (needApproval) {
                                                                       writeLpApprove({args:[
                                                                           routerAddress,
                                                                               BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                                                                       ]})
                                                                       setSuccessLpApprove(() => () => {
                                                                           setNeedApproval(false);
                                                                           setDialogButton('Remove from LP');
                                                                       })
                                                                       return;
                                                                   }
                                                                   const publicClient = getPublicClient()
                                                                   const timestamp = (await publicClient.getBlock({blockNumber: await publicClient.getBlockNumber()})).timestamp;

                                                                   writeRemoveLiquidity({args:[
                                                                           tokenAddress,
                                                                           amount,
                                                                           0,
                                                                           0,
                                                                           address,
                                                                           timestamp+10000n
                                                                       ], })
                                                               }

                                                               setDialogOpen(false);
                                                           });
                                                           setDialogError_max_0('You have no tokens on the LP!')
                                                           setDialogType('remove_liq')
                                                       }
                                                   }}

                                                   onAdd={onAddLiquidity}/>
                                    <LiquidityPoolCard share={share} depositedNavLp={depositedNAVLp}
                                                       depositedBnbLp={depositedBNBLp}
                                                       onAdd={() => {
                                                           setNeedApproval3(lpAllowance < lpBalance.value)
                                        setDialogOpen(true);
                                        setDialogTitle('Farm LP Tokens')
                                        setDialogText('Indicate the amount of LP tokens you would like to farm.')
                                        setDialogBalance(lpBalance)
                                        setDialogButton(!needApproval3 ? 'Farm' : 'Approve')
console.log('reserves', reserves[getNativeCurrency(chainId).symbol]);
console.log('lpbalance', lpBalance.value);
console.log('lptotalsupply', lpTotalSupply);

                                        setDialogNativeBalance(
                                           {
                                               decimals: 18,
                                               formatted: "",
                                               symbol: getNativeCurrency(chainId).symbol,
                                               value: reserves[getNativeCurrency(chainId).symbol] * lpBalance.value / lpTotalSupply
                                           })
                                        setDialogTokenBalance(
                                           {
                                               decimals: 8,
                                               formatted: "",
                                               symbol: TOKEN_NAME,
                                               value: reserves[TOKEN_NAME] * lpBalance.value / lpTotalSupply
                                           })
                                        setDialogResult(() => (amount: bigint) => {

                                            if (amount > 0) {
                                                if (needApproval3) {
                                                    writeLpApprove({args:[
                                                            farmAddress,
                                                            BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                                                        ]})
                                                    setSuccessLpApprove(() => () => {
                                                        setNeedApproval3(false);
                                                        setDialogButton('Farm');
                                                    })
                                                    return;
                                                }

                                                setDialogFetching(true)
                                                writeDepositFarm({args: [0, amount]})
                                            }

                                            setDialogOpen(false);
                                        })
                                        setDialogError_max_0('You don\'t have any LP token! You need to first add liquidity.')
                                        setDialogType('farm_lp')
                                    }} onRemove={() => {
                                        setDialogOpen(true);
                                        setDialogTitle('Remove LP tokens from farming')
                                        setDialogText('Indicate the amount of LP tokens you would like to remove from farming')
                                        setDialogBalance({value:lpUserFarmed})
                                        setDialogButton('Remove from Farming')
                                        console.log(lpUserFarmed, lpTotalSupply)
                                        setDialogNativeBalance(
                                            {
                                                decimals: 18,
                                                formatted: "",
                                                symbol: getNativeCurrency(chainId).symbol,
                                                value: reserves[getNativeCurrency(chainId).symbol] * lpUserFarmed / lpTotalSupply
                                            })
                                        setDialogTokenBalance(
                                            {
                                                decimals: 8,
                                                formatted: "",
                                                symbol: TOKEN_NAME,
                                                value: reserves[TOKEN_NAME] * lpUserFarmed / lpTotalSupply
                                            })
                                        setDialogResult( () => (amount: bigint) => {
                                            if (amount > 0) {
                                                setDialogFetching(true)
                                                writeWithdraw({
                                                    args: [
                                                        0, amount
                                                    ]
                                                })
                                            }

                                            setDialogOpen(false);
                                        })
                                        setDialogError_max_0('You are not farming at the moment!')
                                        setDialogType('unfarm_lp')
                                    }} chainId={chainId}/>
                                    <Farming expectedNavPerYear={expectedNavPerYear} share={share} apy={apy} pendingDistribute={pendingToDistribute} userRewards={userRewards} fetchingFarming={farmingFetching||electrumFetching} onWithdrawRewards={() => {
                                        writeWithdraw({args:[0, 0]});
                                    }}/>

                                </CardContainer>
                            </>
                        ) : section == 2 ? (
                            <Deposit address={depositAddress} gas_cost={gasCost} is_registered={isRegistered}
                                     onRegister={() => {
                                         setDialogFetching(true);
                                         writeRegister();
                                     }}/>
                        ) : section == 3 ? (
                            <Withdraw onWithdraw={(address: string, amount: number) => {
                                writeBurn({args:[amount, address]});
                            }} validateAddress={validateAddress} balance={tokenBalance.value} averageTime={withdrawalAverage}/>
                        ):  (
                                <div>{"Unknown option"}</div>
                            ) :
                        (
                            <>
                                <Grid container spacing={themeOptions.spacing(3)}>
                                    <Grid item xs={12}>
                                        <Button onClick={open} variant="contained">Connect</Button>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </SContent>
            </Drawer>

        </ThemeProvider>
    );
}

export default Bridge;
