import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from "@mui/material/CardHeader";
import ActionButton from "./ActionButton";
import {CircularProgress} from "@mui/material";
import { formatUnits } from 'viem'

export default function Farming(props:any) {
    let {expectedNavPerYear, share, apy, pendingDistribute, userRewards, onWithdrawRewards, fetchingFarming} = props;

    let myExpected = BigInt(expectedNavPerYear * share) / 1000000n;
    return (
        <Card>
            <CardHeader
                title={'Rewards'}
            />
            <CardContent>

            {fetchingFarming ? (
                <CircularProgress/>
            ) : (
                <>
                    <Typography variant={"subtitle1"}>
                        Expected per day
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary"  marginBottom={"20px"}>
                        {expectedNavPerYear > 0n && share > 0n ? formatUnits( myExpected / 365n, 8).toLocaleString() : '-'} wNAV
                    </Typography>
                    <Typography variant={"subtitle1"} marginTop={"20px"}>
                        APY
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" >
                        {(String(apy / 10000n)).toLocaleString()}%
                    </Typography>
                    <Typography variant={"subtitle1"} marginTop={"20px"}>
                        Pending distribution
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" >
                        {formatUnits(pendingDistribute, 8).toLocaleString()} wNAV
                    </Typography>

                    <Typography variant={"subtitle1"} marginTop={"20px"}>
                        Already distributed
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" >
                        {formatUnits(userRewards, 8).toLocaleString()} wNAV
                    </Typography>
                </>
                )}
            </CardContent>

            <CardActions>
                <ActionButton onClick={onWithdrawRewards}>Withdraw rewards</ActionButton>
            </CardActions>
        </Card>
    );
}
